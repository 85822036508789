<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <status class="small" v-bind="{item}" />
        </h1>
        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <i class='bx bxs-cart' />
                {{$t('packages.label')}}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="shipments.label" icon="bxs-truck" :value="item.shipment_id">
                        <router-link :to="{name: `shipments-show`, params: {id: item.shipment_id}}">
                          {{item.$shipment.reference_id}}
                        </router-link>
                    </value>
                  </div>
                  <div class="col">
                    <value label="packages.size" icon="bx-expand-alt" :value="item.size"/>
                  </div>
                  <div class="col">
                    <value label="packages.barcode" icon="bx-barcode" :value="item.barcode"/>
                  </div>
                  <div class="col" v-if="item.description">
                    <value label="packages.description" icon="bx-detail" :value="item.description"/>
                  </div>
                </div>
              </dl>
            </b-card>
            <b-card v-if="item.dropoff_location_id || item.location_id || inTransit">
              <template #header> 
                <i class='bx bxs-map-alt' />
                {{$t('packages.logistics')}}
              </template>
              <div v-if="item.is_consolidated">
                  <b-alert show variant="warning">
                    {{$t('packages.consolidated-with')}}
                    <router-link :to="{name: `packages-show`, params: {id: item.$consolidatedTo.id}}">
                      {{item.$consolidatedTo.barcode}}
                    </router-link>
                  </b-alert>
              </div>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div v-if="item.dropoff_location_id" class="col">
                    <value label="packages.destination" icon="bx-map" :value="item.dropoff_location_id">
                      <router-link v-if="item.dropoff_location_id" :to="{name: `locations-show`, params: {id: item.dropoff_location_id}}">
                        {{item.dropoff_location_name}}
                      </router-link>
                    </value>
                  </div>
                  <div v-if="item.location_id" class="col">
                    <value label="packages.location" icon="bx-map" :value="item.location_name">
                      <router-link v-if="item.location_id" :to="{name: `locations-show`, params: {id: item.location_id}}">
                        {{item.location_name}}
                      </router-link>
                    </value>
                  </div>
                  <div v-if="item.driver_id" class="col">
                    <value label="packages.driver" icon="bxs-user" :value="item.driver_name">
                      <router-link v-if="item.driver_id" :to="{name: `users-show`, params: {id: item.driver_id}}">
                        {{item.driver_name}}
                      </router-link>
                    </value>
                  </div>
                  <div v-if="item.locker_number" class="col">
                    <value label="packages.locker-number" icon="bx-door-open" :value="item.locker_number" />
                  </div>
                  <div v-if="item.available_at" class="col">
                    <value label="packages.available-at" icon="bx-time" :value="item.available_at | moment('LLL')" />
                  </div>
                  <div v-if="item.expires_at" class="col">
                    <value label="packages.expires-at" icon="bx-alarm-exclamation">
                      {{ item.expires_at | moment('LLL') }}<br>
                      <span class="text-info" v-if="item.expiry_extended">
                        {{ $t('packages.expiry_extended') }}
                      </span>
                    </value>
                  </div>
                </div>

              </dl>
            </b-card>

            <b-card v-if="userIsSuperAdmin && item.identification_image">
              <template #header> 
                <i class='bx bx-id-card'></i>
                {{$t('packages.identification')}}
              </template>
              <b-overlay :show="!identification_image" rounded spinner-type="grow" spinner-variant="primary">
                <img v-if="identification_image" :src="identification_image" width="100%">
              </b-overlay>
            </b-card>

          </div>
          <div class="col mb-4">

            <packages-card 
              v-if="item.is_consolidation" 
              :packages="item.$consolidations" 
              title="packages.consolidations"
              icon="bx-unite"
            />

            <activity-card :item="item" />
          </div>
        </div>

      </div>
      <debug v-bind="{item}">
        <b-button v-if="item && item.$meta.actions['runhook']" @click="runHook" variant="link" class="text-info" size="sm"><i class='bx bx-send' ></i> Run webhook</b-button>
        <b-button v-if="item && item.$meta.actions['force-sync']" @click="forceSync" variant="link" class="" size="sm"><i class='bx bx-sync' ></i> Force sync registration</b-button>
      </debug>
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar'
import ActivityCard from '@/components/Cards/Activity'
import Status from '@/components/Status'
import { STATUS_PICKED_UP, STATUS_IN_WAREHOUSE } from '@/resources/Package';
import PackagesCard from '@/components/Cards/Packages'

export default {
  extends: ItemShow,
  components: { 
    MainLayout,
    Value,
    ActionBar,
    ActivityCard,
    Status,
    PackagesCard
  },
  data() {
    return {
      identification_image: null
    }
  },
  computed: {
    inWarehouse() {
      return this.item.status === STATUS_IN_WAREHOUSE
    },
    inTransit() {
      return this.item.status === STATUS_PICKED_UP
    },
  },
  methods: {
    async fetched() {
      if(this.item.identification_image) {
        this.identification_image = await this.item.$getIdentificationImage()
      }
    },
    async runHook() {
      await this.item.$runhook()
      this.$root.$emit('item:refresh')
    },
    async forceSync() {
      await this.item.$forceSync()
      this.$root.$emit('item:refresh')
    }
  }
};
</script>